<template>
  <ClientSkeleton v-if="isLoading" class="px-6 pb-6 pt-5" />
  <div class="container is-fluid pt-5 pb-6 px-6 min-h-90vh" v-else>
    <div class="is-flex is-justify-content-space-between mb-5">
      <div class="is-flex is-align-items-center is-clickable" @click="goBack()">
        <ArrowLeftIcon class="mr-3" />
        <p class="averta-bold is-size-5">Daftar Cabang</p>
      </div>
      <div class="is-flex is-align-items-center">
        <div class="mr-5">
          <b-input
            placeholder="Cari nama cabang"
            type="search"
            icon="magnify"
            v-model="search"
            @keyup.native.enter="getListBranch"
          >
          </b-input>
        </div>
        <div>
          <ButtonApp
            @click="handleAddBranch"
            :is-bold="true"
            height="40px"
            width="254px"
          >
            + Tambah Cabang
          </ButtonApp>
        </div>
      </div>
    </div>
    <BannerClient :client="dataClient" :refreshData="getListBranch" />
    <div class="columns">
      <div class="column card col-1 p-3 m-2">
        <img src="@/assets/img/wave-card.png" alt="wave" class="wave" />
        <p class="is-size-5 averta-bold">Total Cabang</p>
        <p class="is-size-4 averta-black my-2">
          {{ listBranch.card.total_branch }}
        </p>
      </div>
      <div class="column card col-1 p-3 m-2">
        <img src="@/assets/img/wave-card.png" alt="wave" class="wave" />
        <p class="is-size-5 averta-bold">Total Toko</p>
        <p class="is-size-4 averta-black my-2">
          {{ listBranch.card.total_store }}
        </p>
      </div>
      <div class="column card col-1 p-3 m-2">
        <img src="@/assets/img/wave-card.png" alt="wave" class="wave" />
        <p class="is-size-5 averta-bold">Total PIC</p>
        <p class="is-size-4 averta-black my-2">
          {{ listBranch.card.total_pic }}
        </p>
      </div>
    </div>
    <div v-if="listBranch.list.length > 0" class="columns is-flex is-flex-wrap-wrap">
      <div
        class="column is-3"
        v-for="(branch, index) in listBranch.list"
        :key="index"
      >
        <div class="card border-radius-20 border-1-grey-2 list-branch p-4">
          <div class="branch-name mb-4">
            <p class="averta-bold label-20">Cabang {{ branch.name }}</p>
          </div>
          <div
            class="is-flex is-justify-content-space-around wrapper-vendor p-3"
          >
            <div class="has-text-centered flex-1">
              <p>Jumlah Toko</p>
              <p class="averta-bold">{{ branch.total_store }}</p>
            </div>
            <div class="border-right"></div>
            <div class="has-text-centered flex-1">
              <p>Jumlah PIC</p>
              <p class="averta-bold">{{ branch.total_pic }}</p>
            </div>
          </div>
          <div class="mt-5 is-flex is-justify-content-space-around">
            <ButtonApp
              height="45px"
              width="100%"
              class="mr-3"
              :is-bold="true"
              :is-secondary="true"
              @click="handleEditBranch(branch)"
              >Edit</ButtonApp
            >
            <ButtonApp
              height="45px"
              width="100%"
              :is-bold="true"
              @click="detailBranch(branch.id, branch)"
              >Lihat Detail</ButtonApp
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else class="column c-w-100 has-text-centered">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2">
        Data Tidak Ditemukan
      </p>
    </div>

    <SidebarBranch :show="showSidebar" :close="handleCloseSidebar" @refreshListBranch="getListBranch" />
    <SidebarEditBranch
      :show="showSidebarEdit"
      :close="handleCloseSidebarEdit"
      :selectedBranch="selectedBranch"
      @updateBranch="getListBranch"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ArrowLeftIcon, ProyekEmptyIcon } from '@/components/icons'
import ClientSkeleton from '@/components/skeleton/ClientSkeleton.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'
import SidebarBranch from './components/SidebarBranch.vue'
import SidebarEditBranch from './components/branch-components/SidebarEditBranch.vue'
import BannerClient from './components/BannerClient.vue'

export default {
  name: 'ListBranch',
  components: {
    ProyekEmptyIcon,
    ClientSkeleton,
    ArrowLeftIcon,
    ButtonApp,
    SidebarBranch,
    SidebarEditBranch,
    BannerClient
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    pathDetailBranch () {
      const path = this.$route.path.split('/')
      const lastElement = path[path.length - 1]

      if (lastElement === 'detail-branch') return true
      return false
    }
  },
  data () {
    return {
      isLoading: false,
      listBranch: null,
      search: null,
      showSidebar: false,
      showSidebarEdit: false,
      selectedBranch: null,
      dataClient: null
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)

      const clearBranch = {
        type: 'clearPayload',
        data: {
          clientSelected: {},
          branchName: '',
          dataPic: []
        }
      }

      const clearPicBranch = {
        type: 'clearPayload',
        data: {
          clientSelected: {},
          branchSelected: {},
          dataPic: []
        }
      }

      this.$store.commit('client/setPayloadAddBranch', clearBranch)
      this.$store.commit('client/setPayloadAddPicBranch', clearPicBranch)
    },
    detailBranch (branchId, data) {
      this.$router.push(`/am/vendors/${branchId}/store`)
    },
    getListBranch () {
      this.isLoading = true
      const payload = {
        url: this.url,
        vendor_id: this.$route.params.vendorId,
        search: this.search
      }

      this.$store
        .dispatch('client/getListBranch', payload)
        .then(response => {
          this.listBranch = response.data.data
          this.isLoading = false
          const clientSelected = {
            company_name: response.data.data.company_name,
            name: response.data.data.name,
            logo: response.data.data.logo,
            id: this.$route.params.vendorId
          }

          this.dataClient = {
            name: response.data.data.name,
            company_name: response.data.data.company_name,
            logo: response.data.data.logo,
            client_type_code: response.data.data.client_type_code,
            client_type_name: response.data.data.client_type_name
          }

          // Tambah response detail client
          this.$store.commit('client/setPayloadAddBranch', {
            type: 'clientSelected',
            clientSelected: clientSelected
          })
          this.$store.commit('client/setPayloadAddPicBranch', {
            type: 'clientSelected',
            clientSelected: clientSelected
          })
          this.$store.commit('client/setDetailClient', { data: clientSelected })
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message: '<p class="averta-regular">Gagal memuat list cabang, silahkan coba lagi</p>',
            type: 'is-danger'
          })
        })
    },
    handleAddBranch () {
      this.showSidebar = true
      this.$store.commit('client/setAddBranchFromList', true)
    },
    handleCloseSidebar () {
      this.showSidebar = false
    },
    handleCloseSidebarEdit () {
      this.showSidebarEdit = false
      this.selectedBranch = null
    },
    handleEditBranch (data) {
      this.showSidebarEdit = true
      this.selectedBranch = data
    }
  },
  created () {
    this.getListBranch()
  },
  watch: {
    search () {
      if (this.search === null || this.search === '') {
        this.getListBranch()
      }
    }
  }
}
</script>

<style scoped>
.col-1 {
  background: linear-gradient(90deg, #ed4949 0%, #a40707 100%);
  border-radius: 20px;
  color: white;
}

.wave {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 20px;
}

.wrapper-vendor {
  background: #f7f7f7;
  border-radius: 18px;
}

.border-right {
  border-right: 2px #eaeaea solid;
}

.btn-detail {
  border-radius: 20px;
  color: #eb4600;
  background: white;
  border: 1px solid #eb4600;
  padding-top: 14px;
  padding-bottom: 14px;
  font-family: "Averta-Bold";
  font-size: 16px;
  width: 100%;
}

.list-branch {
  height: 245px;
}

.branch-name {
  height: 60px;
}

.branch-name p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
