<template>
  <div>
    <ModalTemplate
      :showModal="showModalHistory"
      width="790px"
      @closeModal="closeModalHistory"
    >
      <template v-slot:headerModal>
        <div
          class="is-flex is-justify-content-space-between is-align-items-center"
        >
          <div class="flex-center-vertical">
            <HistoryLineIcon color="#fff" class="mr-3" />
            <p class="averta-bold label-18">Riwayat Perubahan</p>
          </div>
          <div class="is-clickable" @click="closeModalHistory">
            <b-icon icon="close"></b-icon>
          </div>
        </div>
      </template>
      <template v-slot:bodyModal>
        <div class="wrap-body-modal p-5">
          <div v-if="dataHistory.length > 0">
            <div class="wrap-table mb-5">
              <table
                class="table is-bordered is-narrow is-hoverable is-fullwidth"
              >
                <tr class="bg-dark-grey-5 has-text-white has-text-centered">
                  <td class="py-4 averta-bold" style="width: 10%;">NO</td>
                  <td class="py-4 averta-bold" style="width: 25%;">
                    DIUBAH OLEH
                  </td>
                  <td class="py-4 averta-bold" style="width: 25%;">
                    JENIS LAMPIRAN
                  </td>
                  <td class="py-4 averta-bold" style="width: 65%;">CATATAN</td>
                </tr>
                <tr
                  class="tr-wrapper"
                  v-for="(item, index) in dataHistory"
                  :key="`data-history-${index}`"
                >
                  <td class="py-3 has-text-centered">{{ index + 1 }}</td>
                  <td>
                    <div class="py-3">
                      <p class="mb-1 white-space-nowrap averta-bold">
                        {{ item.name }}
                      </p>
                      <p class="color-gray">
                        {{ formatDate(item.created_at) }}
                      </p>
                    </div>
                  </td>
                  <td class="averta-bold">
                    {{ item.type }}
                  </td>
                  <td>
                    <div class="label-14 note-desc-wrapper">
                      <p
                        class="averta-bold white-space-pre-line word-wrap-break"
                      >
                        {{ item.note || "-" }}
                      </p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div
            v-if="dataHistory.length < 1"
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
          >
            <ProyekEmptyIcon class="mb-3" />
            <p class="averta-bold color-black">Tidak Ada Riwayat Perubahan</p>
          </div>
        </div>
      </template>
    </ModalTemplate>
  </div>
</template>

<script>
import { formatDateHelper } from '@/helpers'
import { HistoryLineIcon, ProyekEmptyIcon } from '@/components/icons'
import ModalTemplate from '@/components/modal/ModalTemplate.vue'

export default {
  name: 'ModalHistoryAttachment',
  components: {
    ModalTemplate,
    HistoryLineIcon,
    ProyekEmptyIcon
  },
  props: {
    showModalHistory: {
      type: Boolean,
      default: false
    },
    dataHistory: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    closeModalHistory () {
      this.$emit('closeModal')
    },
    formatDate (date) {
      return formatDateHelper({ date })
    }
  }
}
</script>

<style scoped>
.wrap-body-modal {
  max-height: 500px;
  overflow-y: auto;
}

.tr-wrapper td {
  vertical-align: middle;
}
</style>
