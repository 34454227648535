<template>
  <div class="px-0 container is-fluid">
    <SnackbarRescheduleSurvey v-if="dataTotalReschedule > 0" :total="dataTotalReschedule" @buttonClick="showRescheduleSurvey" />
    <title-bar :title-stack="titleStack" class="mb-5 px-5 pt-5" :style="{'margin-top': dataTotalReschedule > 0 ? '75px' : '0px'}" />

    <div v-if="isLoadingCard" class="performa-harian-wrapper px-5">
      <CardSkeleton :totalCard="4" />
    </div>
    <div v-else class="performa-harian-wrapper px-5">
      <p class="averta-bold is-size-4">Performa Hari Ini</p>
      <p>Terakhir Update: ~ {{ convertDate(dataCard.last_update) }}</p>

      <div class="columns mt-3">
        <div class="column card col-1 p-3 m-2">
          <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
          <div class="card-body-proyek">
            <p class="is-size-5 averta-bold">Konsultan Aktif</p>
            <p class="is-size-4 averta-black my-2">{{ dataCard.daily && dataCard.daily.consultan_active }}</p>
          </div>
        </div>
        <div class="column card col-1 p-3 m-2">
          <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
          <div class="card-body-proyek">
            <p class="is-size-5 averta-bold">Survey Berlangsung</p>
            <p class="is-size-4 averta-black my-2">{{ dataCard.daily.survey_in_progress }}</p>
          </div>
        </div>
        <div class="column card col-1 p-3 m-2">
          <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
          <div class="card-body-proyek">
            <p class="is-size-5 averta-bold">Survey Selesai</p>
            <p class="is-size-4 averta-black my-2">{{ dataCard.daily.survey_complete }}</p>
          </div>
        </div>
        <div class="column card col-1 p-3 m-2">
          <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
          <div class="card-body-proyek">
            <p class="is-size-5 averta-bold">Penawaran Dibuat</p>
            <p class="is-size-4 averta-black my-2">{{ dataCard.daily.offering_created }}</p>
          </div>
        </div>
        <div class="column card col-2 p-3 m-2">
          <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
          <div class="card-body-proyek">
            <p class="is-size-5 averta-bold">Target Tercapai</p>
            <div class="is-flex is-align-items-center">
              <p class="is-size-4 averta-black my-2 mr-3">{{ dataCard.daily.target }} %</p>
              <UpIcon :class="{ 'flip-icon': dataCard.daily.target <= 50 }" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isLoadingBerlangsung" class="card-penawaran-wrapper px-5 py-3">
      <CardSkeleton :totalCard="3" height="200px" />
    </div>
    <div v-else-if="dataPenawaran && dataPenawaran.length > 0" class="card-penawaran-wrapper px-5 py-3">
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <div>
          <p class="averta-bold is-size-4">Selesaikan Penawaran</p>
          <p>Segera lengkapi penawaran untuk melanjutkan proses</p>
        </div>
        <p class="text-lihat is-clickable averta-bold" @click="() => this.$router.push('/am/project')">Lihat Semua</p>
      </div>

      <div class="columns mt-3 parrent-list pb-3">
        <div class="column card list-penawaran is-4 p-5 m-2 is-clickable is-flex is-flex-direction-column"
          @click="goToProject(data.survey_id)" v-for="data, index in dataPenawaran" :key="`${data.id}-offering-${index}`">
          <div class="columns card-atas pb-2 is-flex-grow-1 mb-0">
            <div class="column">
              <p>{{ data.survey_complain_code ? data.survey_complain_code : data.survey_id }}</p>
              <p class="store averta-bold">{{ data.store.name }}</p>
            </div>
            <div class="has-text-centered">
              <div class="column has-text-right-desktop px-0">
                <span class="p-1 status-penawaran" :class="getClassName(data.status)">{{ wordingMenu(data.status) }}</span>
              </div>
              <div class="is-flex">
                <div class="mr-2 wrap-logo is-flex is-align-items-center is-align-items-center">
                  <img :src="data.vendor.logo" :alt="data.vendor.name">
                </div>
                <p class="averta-bold">{{ data.vendor.name }}</p>
              </div>
            </div>
          </div>
          <div class="columns card-bawah mt-4">
            <div class="column">
              <p class="mb-2">Tgl Survey Selesai</p>
              <p class="averta-bold">{{ convertDate(data.survey_date) }}</p>
            </div>
            <div class="column">
              <p class="mb-2">Tgl Pembuatan Penawaran</p>
              <p class="averta-bold">{{ convertDate(data.created_at) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isLoadingCard" class="px-5 mb-6 mt-3">
      <CardSkeleton :totalCard="4" height="200px" />
    </div>
    <div v-else class="px-5 mb-6 mt-3">
      <div class="is-flex is-align-items-center is-justify-content-space-between">
        <p class="averta-bold is-size-4">Performa Bulanan</p>
        <b-dropdown aria-role="list" v-model="selectedMonth">
          <template #trigger="{ active }">
            <b-button :label="selectedMonth ? selectedMonth.month : 'Pilih Bulan'"
              :icon-right="active ? 'menu-up' : 'menu-down'" />
          </template>

          <b-dropdown-item aria-role="listitem" v-for="data in listMonth" :key="`${data.value}-month`" :value="data"
            @click="performaBulanan()">
            {{ data.month }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="columns mt-3">
        <div class="column card col-month-1 p-3 m-2">
          <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
          <div class="card-body-proyek">
            <p class="is-size-5 averta-bold">Penawaran Disetujui Client</p>
            <p class="is-size-2 averta-bold my-2">{{ dataCard.monthly.offering_approved }}</p>
            <hr />
            <a class="bottom-card" @click="() => this.$router.push('/am/project')">
              <span class="mr-2 averta-bold">Selengkapnya</span>
              <ArrowRightIcon />
            </a>
          </div>
        </div>
        <div class="column card col-month-2 p-3 m-2">
          <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
          <div class="card-body-proyek">
            <p class="is-size-5 averta-bold">Proyek Berjalan</p>
            <p class="is-size-2 averta-bold my-2">{{ dataCard.monthly.project_active }}</p>
            <hr />
            <a class="bottom-card" @click="() => this.$router.push('/am/project')">
              <span class="mr-2 averta-bold">Selengkapnya</span>
              <ArrowRightIcon />
            </a>
          </div>
        </div>
        <div class="column card col-month-3 p-3 m-2">
          <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
          <div class="card-body-proyek">
            <p class="is-size-5 averta-bold">Proyek Selesai</p>
            <p class="is-size-2 averta-bold my-2">{{ dataCard.monthly.project_complete }}</p>
            <hr />
            <a class="bottom-card" @click="() => this.$router.push('/am/project')">
              <span class="mr-2 averta-bold">Selengkapnya</span>
              <ArrowRightIcon />
            </a>
          </div>
        </div>
        <div class="column card col-month-4 p-3 m-2">
          <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
          <div class="card-body-proyek">
            <p class="is-size-5 averta-bold">Proyek Lunas</p>
            <p class="is-size-2 averta-bold my-2">{{ dataCard.monthly.project_paid }}</p>
            <hr />
            <a class="bottom-card" @click="() => this.$router.push('/am/project')">
              <span class="mr-2 averta-bold">Selengkapnya</span>
              <ArrowRightIcon />
            </a>
          </div>
        </div>
        <div class="column card col-month-5 p-3 m-2">
          <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
          <div class="card-body-proyek">
            <p class="is-size-5 averta-bold">Total Belum Bayar</p>
            <p class="is-size-2 averta-bold my-2">{{ formatThousand(dataCard.monthly.total_not_paid) }}</p>
            <hr />
            <a class="bottom-card" @click="() => this.$router.push('/am/project')">
              <span class="mr-2 averta-bold">Selengkapnya</span>
              <ArrowRightIcon />
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Reschedule Survey -->
    <ModalRescheduleSurvey :showModal="modalRescheduleSurvey" width="75vw" @closeModal="showRescheduleSurvey" @totalReschedule="totalReschedule($event)" :isDashboard="true"/>

  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import TitleBar from '@/components/TitleBar'
import CardSkeleton from '@/components/skeleton/CardSkeleton'
import firsLoginImage from '@/assets/img/firs-login.png'
import { UpIcon, ArrowRightIcon } from '@/components/icons'
import { formatCurrency, wordingMenuHelper, getStatusClassName } from '@/helpers'
import SnackbarRescheduleSurvey from '@/components/snackbar/SnackbarRescheduleSurvey.vue'
import ModalRescheduleSurvey from '@/components/modal/ModalRescheduleSurvey.vue'

export default {
  name: 'DashboardAM',
  components: {
    TitleBar,
    CardSkeleton,
    UpIcon,
    ArrowRightIcon,
    SnackbarRescheduleSurvey,
    ModalRescheduleSurvey
  },
  computed: {
    titleStack () {
      return ['Dashboard']
    },
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user',
      firstLogin: 'auth/firstLogin'
    })
  },
  data () {
    return {
      isLoadingCard: false,
      isLoadingBerlangsung: false,
      dataCard: null,
      dataPenawaran: [],
      selectedMonth: null,
      listMonth: [],
      modalRescheduleSurvey: false,
      dataTotalReschedule: null
    }
  },
  methods: {
    formatThousand (x) {
      return formatCurrency(x)
    },
    goToProject (id) {
      this.$router.push(`/am/project/survey/${id}/detail`)
    },
    convertDate (date) {
      if (date === null) {
        return ''
      }
      return moment(date).format('DD MMMM YYYY')
    },
    getDataCard (payload) {
      this.isLoadingCard = true
      this.$store
        .dispatch('dashboard/getCardDashboard', payload)
        .then(response => {
          this.isLoadingCard = false
          this.dataCard = response.data.data
        })
        .catch(error => {
          this.isLoadingCard = false
          console.log(error)
        })
    },
    getSurveyBerlangsung () {
      this.isLoadingBerlangsung = true
      const payload = {
        url: this.url
        // page: 1
      }

      this.$store
        .dispatch('project/getListPenawaranDashboard', payload)
        .then(response => {
          this.dataPenawaran = response.data.data.list
          this.isLoadingBerlangsung = false
        })
        .catch(error => {
          this.isLoadingBerlangsung = false
          console.log(error)
        })
    },
    performaBulanan () {
      const data = {
        start_date: moment(this.selectedMonth.value).startOf('month').format('YYYY-MM-DD'),
        end_date: moment(this.selectedMonth.value).endOf('month').format('YYYY-MM-DD')
      }

      const payload = {
        ...data,
        url: this.url
      }

      this.getDataCard(payload)
    },
    generateMonth () {
      for (let month = 0; month <= 11; month++) {
        const temp = {
          month: moment().month(month).format('MMMM YYYY'),
          value: moment().month(month).format('YYYY-MM')
        }
        this.listMonth.push(temp)
      }
    },
    showRescheduleSurvey () {
      this.modalRescheduleSurvey = !this.modalRescheduleSurvey
    },
    totalReschedule (data) {
      this.dataTotalReschedule = data
    },
    getClassName (status) {
      return getStatusClassName(status)
    },
    wordingMenu (value) {
      return wordingMenuHelper(value)
    }
  },
  created () {
    const payload = { url: this.url }
    this.getDataCard(payload)
    this.getSurveyBerlangsung()
    this.generateMonth()
  },
  mounted () {
    if (this.firstLogin) {
      this.$swal({
        imageUrl: firsLoginImage,
        imageAlt: 'first login image',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: `<p class="averta-bold label-22">Hallo! ${this.user.name}</p>`,
        html: '<p>Selamat datang di Dashboard Business Support</p>',
        confirmButtonText: 'Mulai',
        confirmButtonColor: '#EB4600',
        customClass: {
          confirmButton: 'btn-first-login'
        }
      }).then(() => {
        this.$store.commit('auth/setFirstLogin', false)
      })
    }

    document.documentElement.classList.remove('has-aside-left')
    document.documentElement.classList.remove('has-navbar-fixed-top')
  }
}
</script>

<style scoped>
.performa-harian-wrapper {
  margin-bottom: 30px;
}

.card-body-proyek {
  z-index: 1;
  position: relative;
}

.text-lihat {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.wave {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 20px;
}

.col-1 {
  background: linear-gradient(90deg, #751ce6 0%, #552bff 100%);
  border-radius: 20px;
  color: white;
}

.col-2 {
  background: linear-gradient(116.12deg, #1ede31 16.15%, #72de1e 83.24%);
  border-radius: 20px;
  color: white;
}

.flip-icon {
  transform: rotate(-180deg);
}

.card-penawaran-wrapper {
  background: #FAFAFA;
}

.parrent-list {
  overflow-x: auto;
}

.list-penawaran {
  background: white;
  border-radius: 20px;
}

.store {
  color: #D9272D;
}

.status-penawaran {
  border-radius: 20px;
  color: white;
  font-size: 12px;
}

.card-atas {
  border-bottom: 2px dashed #868686;
}

.card-bawah {
  background: linear-gradient(90deg, rgba(255, 221, 221, 0.4) 0%, rgba(255, 239, 219, 0.4) 99.97%);
  border-radius: 12px;
}

.col-month-1 {
  background: linear-gradient(90deg, #3185FC 0%, #31B3FC 100%);
  border-radius: 20px;
  color: white;
}

.col-month-2 {
  background: linear-gradient(90deg, #FFA200 0%, #F5BB54 100%);
  border-radius: 20px;
  color: white;
}

.col-month-3 {
  background: linear-gradient(90deg, #10BD41 0%, #55F883 100%);
  border-radius: 20px;
  color: white;
}

.col-month-4 {
  background: linear-gradient(90deg, #EB4600 0%, #FF8C5B 100%);
  border-radius: 20px;
  color: white;
}

.col-month-5 {
  background: linear-gradient(90deg, #ED4949 0%, #A40707 100%);
  border-radius: 20px;
  color: white;
}

.bottom-card {
  display: flex;
  justify-content: end;
  align-items: center;
  color: white;
  z-index: 1;
}

.bottom-card svg {
  fill: white;
}

.wrap-logo img {
  height: 30px;
  object-fit: contain;
}

</style>
