<template>
  <DetailSkeleton v-if="isLoading" class="p-6" />
  <div v-else class="body-wrapper container container-detail">
    <!-- IF EMPTY DATA -->
    <div v-if="!this.dataOffer || Object.keys(this.dataOffer).length === 0"
      class="not-found-wrapper container is-fluid pt-5 pb-6 px-6">
      <div class="is-flex is-align-items-center is-clickable mb-5 mt-3 is-hidden-mobile"
        @click="() => this.$router.push('/purchasing/project')">
        <ArrowLeftIcon class="mr-3" />
        <p class="is-size-5 averta-bold">Kembali ke list</p>
      </div>
      <DataNotFound />
    </div>

    <!-- IF DATA FOUND -->
    <div v-else class="container container-detail pt-5 pb-6">

      <!-- STICKY HEADER -->
      <div class="sticky-header" :style="{ top: navbarPosition }">
        <div class="is-flex is-align-items-center ml-sm-1">
          <div class="is-flex is-align-items-center is-clickable" @click="goBack">
            <ArrowLeftIcon class="mr-3" />
          </div>
          <div>
            <div class="is-flex is-align-items-center flex-sm-column align-items-sm-start">
              <span class="mr-3 averta-bold label-18">{{ conditionalIDProject }} | {{ dataOffer.store.name }}</span>
              <b-tooltip position="is-bottom" type="is-dark" :triggers="['hover']" :auto-close="true">
                <template v-slot:content>
                  <div v-if="dataOffer.tooltips" class="is-flex is-align-items-center wrapper-tooltip">
                    <i class="mdi mdi-information mr-3"></i>
                    <span>{{ dataOffer.tooltips }}</span>
                  </div>
                </template>
                <TagStatus :statusTag="dataOffer.status" :tooltips="dataOffer.tooltips" />
              </b-tooltip>
            </div>
          </div>
        </div>
      </div>

      <div class="is-flex is-align-items-center is-clickable mb-5 mt-3 is-hidden-mobile btn-back-wrapper"
        @click="goBack">
        <ArrowLeftIcon class="mr-3" />
        <p class="is-size-5 averta-bold">Kembali ke list</p>
      </div>

      <div class="is-flex is-justify-content-space-between is-align-items-center mb-3">
        <div class="is-flex is-align-items-center mt-4">
          <div class="is-hidden-desktop is-hidden-tablet" @click="() => this.$router.push('/project')">
            <ArrowLeftIcon class="mr-3" />
          </div>
          <div>
            <div class="is-flex is-align-items-center flex-sm-column align-items-sm-start">
              <span class="mr-3 averta-bold text-id-proyek">
                {{ conditionalIDProject }} | {{ dataOffer.store.name }}
              </span>

              <b-tooltip position="is-bottom" type="is-dark" :triggers="['hover']" :auto-close="true">
                <template v-slot:content>
                  <div v-if="dataOffer.tooltips" class="is-flex is-align-items-center wrapper-tooltip">
                    <i class="mdi mdi-information mr-3"></i>
                    <span>{{ dataOffer.tooltips }}</span>
                  </div>
                </template>
                <TagStatus :statusTag="dataOffer.status" :tooltips="dataOffer.tooltips" />
              </b-tooltip>
            </div>
            <!-- Tag Redirect -->
            <div
              v-if="dataOffer.project_complain && dataOffer.project_complain.project_parent_id"
              class="tag-redirect is-flex is-align-items-center is-justify-content-center is-disabled"
            >
              <TagRedirect class="mr-3" color="#868686"/>
              <p class="mr-3">
                <span class="averta-bold label-16 text-grey">ID Terkait : </span>
                <span class="averta-bold label-16 text-grey">
                  {{ dataOffer.project_complain.survey_parent_id }}
                </span>
              </p>
            </div>
            <!-- Tag Redirect -->
            <p class="is-hidden-mobile label-14 mt-3 mb-2">
              Cabang {{ dataOffer.store.name ? dataOffer.store.name : '-' }}
            </p>
          </div>
        </div>
      </div>

      <!-- Group ID Survey & Proyek -->
      <SurveyProjectID :dataHeaderProps="dataOffer" @onModalProyekDarurat="handleProyekDarurat($event)" />

      <div>
        <CardStickyNotif v-if="dataOffer.badge" :badge="dataOffer.badge" :can_complete_offering="dataOffer.can_complete_offering" @submit="completedOffer" :newMaterial="dataOffer.new_materials" @openModalConfirmMaterial="$event => handleModalConfirmMaterial($event, true)" />
      </div>
      <div class="mb-5 mt-5" style="border-bottom: 2px dashed #9A9A9A;"></div>
      <div>
        <b-tabs v-model="activeTab">
          <b-tab-item label="Detail Penawaran">
            <DetailOfferProject :data-offer="dataOffer" @getDetailOffer="getDetailOffer" />
          </b-tab-item>
          <!-- <b-tab-item label="Riwayat Reschedule">
            <TabDetailHistoryReschedule :dataHistoryReschedule="dataSurvey.reschedule_request_histories" />
          </b-tab-item> -->
          <b-tab-item label="Catatan Proyek">
            <ProjectNote class="mb-5" :surveyId="parseInt(dataOffer.survey_id)" />
            <PersonalNote :notes="dataOffer.note" :surveyId="parseInt(dataOffer.survey_id)" :loadData="getDetailOffer" />
          </b-tab-item>
        </b-tabs>
      </div>
    </div>

    <!-- Modal Preview -->
    <b-modal v-model="isShowCompletedOffer" width="80vw" :can-cancel="false" class="modal-preview">
      <div class="content-modal">
        <div class="modal-header is-flex is-justify-content-space-between is-align-items-center">
          <div class="is-flex">
            <img :src="require('@/assets/icons/tool.svg')" alt="tools-icon" class="mr-3">
            <p class="averta-bold has-text-white label-20">Lengkapi Penawaran</p>
          </div>
          <div class="is-clickable" @click="isShowCompletedOffer = false">
            <b-icon icon="close"></b-icon>
          </div>
        </div>
        <div class="modal-body">
          <div class="wrap-preparasi is-flex is-justify-content-space-between is-align-items-center">
            <div class="ml-5">
              <p class="averta-bold label-16 mb-0">Tentukan Durasi Preparasi <span class="text-merah is-italic">* </span></p>
              <p class="my-2">Masukan durasi preparasi yang dibutuhkan untuk mempersiapkan keseluruhan material proyek</p>
              <div class="has-background-white is-flex wrap-input is-align-items-center is-justify-content-space-between pr-3 mb-3">
                <input class="px-3" placeholder="0" min="0" type="number" v-model="durationPreparation" />
                <span>Hari</span>
              </div>
              <p v-if="message !== ''" class="text-merah">{{ message }}</p>
            </div>
            <div>
              <img :src="require('@/assets/icons/preparation.svg')">
            </div>
          </div>
          <div class="wrap-offering-preparasi">
            <NewDetailPenawaran :isPurchasing="true" :purchasingModal="true" :hideInformasiPenawaran="true" :hideTimeline="true" class="px-5 wrap-detail-penawaran" />
          </div>
          <div class="column has-text-right wrap-footer-preparasi">
            <b-button class="averta-bold btn-lengkapi label-14" rounded @click="sendCompleteOffer" icon-left="check-circle">
              Kirim ke Business Support
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Modal Preview -->

    <!-- Modal Confirm Material -->
    <b-modal v-model="showModalConfirm" width="75vw" :can-cancel="false" class="modal-preview">
      <div class="content-modal">
        <div class="modal-header is-flex is-justify-content-space-between is-align-items-center">
          <div class="is-flex">
            <img :src="require('@/assets/icons/tool.svg')" alt="tools-icon" class="mr-3">
            <p class="averta-bold has-text-white label-20">Lengkapi Penawaran</p>
          </div>
          <div class="is-clickable" @click="showModalConfirm = false">
            <b-icon icon="close"></b-icon>
          </div>
        </div>
        <div class="modal-body p-6">
          <div class="mb-6">
            <p class="averta-bold label-16">Konsultan survey menambahkan Material Baru <span class="text-merah">*</span></p>
            <p>Konfirmasi material baru untuk melanjutkan proses penawaran</p>
          </div>
          <div class="wrap-material">
            <div class="content-collapse columns" v-for="material, indexMaterial in dataOffer.new_materials" :key="`${indexMaterial}-new-material`">
              <div class="column is-4">
                <p class="has-text-grey">Nama Material</p>
                <p class="averta-bold">{{ material.name }} <span v-if="material.is_custom === 0" class="averta-regular text-merah ml-2"><i>Material Custom</i></span></p>
              </div>
              <div class="column is-3">
                <p class="has-text-grey">Jumlah Dibutuhkan</p>
                <p class="averta-bold">{{ material.total_required }}</p>
              </div>
              <div class="column is-3">
                <p class="has-text-grey">Jumlah Dipenawaran</p>
                <p class="averta-bold">{{ material.total_offer }}</p>
              </div>
              <div class="column is-2 is-flex is-justify-content-center" v-if="material.is_confirmed === 0">
                <div class="wrap-button is-flex is-justify-content-center" @click="handleModalConfirmMaterial(material)">
                  <p class="averta-bold white-space-nowrap">Konfirmasi Material</p>
                </div>
              </div>
              <div class="column is-2 is-flex is-justify-content-center" v-else>
                <img :src="require('@/assets/img/confirm-material.svg')" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Modal Confirm Material -->

    <!-- Modal Material -->
    <ModalMaterials typeModal="confirm-material" :id="dataModalConfirmMaterial.product_id" :nameProps="dataModalConfirmMaterial.name" :previewPhotoProps="dataModalConfirmMaterial.picture" :showModal="showModalConfirmMaterial" @closeModal="($event) => closeModalConfirmMaterial($event)" />
    <!-- Modal Material -->

    <ModalProyekDarurat :dataModalUrgent="dataOffer.project_urgent_id" :surveyId="dataOffer.survey_id" :showModal="modalProyekDarurat" :width="isMobile ? '95vw' : '45vw'" @closeModal="handleProyekDarurat(false)" />

    <b-loading :is-full-page="true" v-model="isLoadingSpinner" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ArrowLeftIcon, TagRedirect, SpinnerIcon } from '@/components/icons'
import { alertErrorMessage } from '@/helpers'
import DataNotFound from '@/components/DataNotFound'
import DetailSkeleton from '@/components/skeleton/DetailSkeleton'
import TagStatus from '../../components/TagStatus.vue'
import DetailOfferProject from './DetailOfferProject.vue'
import successCreateImage from '../../../../../assets/img/survey-selesai.png'
import CardStickyNotif from '../../components/card/CardStickyNotif.vue'
import confirmOfferingImage from '@/assets/img/delete-area-component.png'
import NewDetailPenawaran from '@/components/project/NewDetailPenawaran'
import ModalMaterials from '../../components/modal/ModalMaterials.vue'
import TabDetailHistoryReschedule from '@/components/project/TabDetailHistoryReschedule.vue'
import ProjectNote from '@/components/project/detail/ProjectNote.vue'
import PersonalNote from '@/components/project/detail/PersonalNote.vue'
import SurveyProjectID from '@/components/project/detail/SurveyProjectID.vue'
import ModalProyekDarurat from '@/components/modal/ModalProyekDarurat.vue'

export default {
  name: 'IndexOfferProject',
  components: {
    DetailSkeleton,
    TagStatus,
    DataNotFound,
    ArrowLeftIcon,
    TagRedirect,
    SpinnerIcon,
    DetailOfferProject,
    CardStickyNotif,
    NewDetailPenawaran,
    ModalMaterials,
    // eslint-disable-next-line vue/no-unused-components
    TabDetailHistoryReschedule,
    ProjectNote,
    PersonalNote,
    SurveyProjectID,
    ModalProyekDarurat
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      heightNavbar: 'dashboard/getNavbarHeight'
    }),
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    },
    conditionalIDProject () {
      if (this.dataOffer.project_complain && this.dataOffer.project_complain.survey_complain_code) {
        return this.dataOffer.project_complain.survey_complain_code
      } else {
        return this.dataOffer.survey_id
      }
    }
  },
  data () {
    return {
      isLoading: true,
      dataOffer: null,
      showModalConfirmMaterial: false,
      dataModalConfirmMaterial: {
        product_id: null,
        name: null,
        picture: null
      },
      activeTab: 0,
      isShowCompletedOffer: false,
      widthModalOffer: '',
      durationPreparation: null,
      message: '',
      showModalConfirm: false,
      isFromCard: false,
      modalProyekDarurat: false,
      navbarPosition: null,
      isLoadingSpinner: false
    }
  },
  methods: {
    getDetailOffer () {
      this.isLoading = true
      const payload = {
        url: this.url,
        offer_id: this.$route.params.offerId
      }
      this.$store
        .dispatch('project/projectOfferApi', payload)
        .then(response => {
          this.isLoading = false
          this.dataOffer = response.data.data
          this.$store.commit('project/setDataDetailPenawaran', response.data.data)
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message: 'Gagal memuat data detail survey, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    sendCompleteOffer () {
      if (this.durationPreparation === null || this.durationPreparation === '') {
        this.message = 'Harap isi durasi preparasi dengan benar'
      } else {
        this.$swal({
          imageUrl: confirmOfferingImage,
          imageAlt: 'confirm complete offering',
          width: '600px',
          padding: '10px 10px 30px 10px',
          title: '<p class="averta-bold label-22">Lanjut Proses Penawaran Ini?</p>',
          html: '<p class="averta-regular">Pastikan kamu sudah cek kembali penawaran ini sebelum di proses. Penawaran akan dikirimkan ke Business Support</p>',
          showCancelButton: true,
          cancelButtonText: 'Cek Kembali',
          confirmButtonText: 'Kirim Penawaran',
          customClass: {
            cancelButton: 'btn-batal-detail-component',
            confirmButton: 'btn-hapus-detail-component'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.isLoadingSpinner = true

            const payload = {
              url: this.url,
              offer_id: +this.$route.params.offerId,
              duration: +this.durationPreparation
            }

            this.$store
              .dispatch('project/postCompleteOffer', payload)
              .then(response => {
                this.isLoadingSpinner = false

                this.$swal({
                  width: '600px',
                  padding: '10px 10px 30px 10px',
                  html: `
                  <img src="${successCreateImage}" class="img-create-penawaran mb-4" />
                  <p class="averta-bold mb-4 label-22">Penawaran berhasil dibuat</p>
                  <p class="averta-reguler">Penawaran berhasil dibuat. Business Support akan melakukan review dan melengkapi informasi lainnya.</p>
                `,
                  showCancelButton: true,
                  cancelButtonText: 'Lihat Daftar Proyek',
                  confirmButtonText: 'Lihat Detail',
                  customClass: {
                    cancelButton: 'btn-batal-detail-component',
                    confirmButton: 'btn-hapus-detail-component'
                  }
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.message = ''
                    this.isShowCompletedOffer = false
                    this.getDetailOffer()
                  } else {
                    console.log('error!')
                    this.$router.push('/purchasing/project')
                  }
                })
              })
              .catch(error => {
                this.isLoadingSpinner = false
                alertErrorMessage(error)
                this.isShowCompletedOffer = false
              })
          }
        })
      }
    },
    completedOffer () {
      let validation = false

      this.dataOffer.new_materials.forEach((item, index) => {
        if (item.is_confirmed === 0) {
          validation = true
        }
      })

      if (validation === true) {
        this.showModalConfirm = true
      } else {
        this.isShowCompletedOffer = true
        this.widthModalOffer = '75vw'
      }
    },
    closeCompletedOffer () {
      this.isShowCompletedOffer = false
    },
    handleModalConfirmMaterial (data, fromCard) {
      this.showModalConfirmMaterial = !this.showModalConfirmMaterial
      this.dataModalConfirmMaterial = data
      this.showModalConfirm = false

      // if user click Konfirmasi Material from CardStickyNotif, change isFromCard = true to not open showModalConfirm
      if (fromCard) {
        this.isFromCard = true
      } else {
        this.isFromCard = false
      }
    },
    closeModalConfirmMaterial (event) {
      if (event === 'success-confirm-material') {
        this.getDetailOffer()
        this.isFromCard = true
        this.showModalConfirm = false
      }
      this.showModalConfirmMaterial = false
      if (this.isFromCard) {
        this.showModalConfirm = false
      } else {
        this.showModalConfirm = true
      }
    },
    goBack () {
      this.$router.push('/purchasing/project')
    },
    handleScroll () {
      const stickyHeader = document.querySelector('.sticky-header')
      const limitScroll = this.isMobile ? 130 : 200

      if (window.scrollY > limitScroll) {
        stickyHeader.classList.add('is-pinned')
        setTimeout(() => {
          stickyHeader.classList.add('transition')
        }, 1)
        if (this.isMobile) {
          stickyHeader.classList.add('mobile')
        }
      } else {
        stickyHeader.classList.remove('is-pinned')
        stickyHeader.classList.remove('transition')

        if (this.isMobile) {
          stickyHeader.classList.remove('mobile')
        }
      }
    },
    handleProyekDarurat (props = {}) {
      const { value } = props
      this.modalProyekDarurat = value
    },
    adjustStickyPosition () {
      setTimeout(() => {
        if (this.heightNavbar) {
          this.navbarPosition = this.heightNavbar + 'px'
        }
      }, 600)
    }
  },
  watch: {
    heightNavbar (val) {
      if (val) {
        this.adjustStickyPosition()
      }
    },
    durationPreparation (val) {
      if (val >= 0) {
        this.message = ''
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    this.adjustStickyPosition()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  created () {
    this.getDetailOffer()
  }
}
</script>

<style scoped>
.body-wrapper {
  min-height: 90vh
}

.not-found-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.text-id-proyek {
  font-size: 24px;
}

.label-14 {
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
}

/* .label-16 {
  font-size: 16px;
  line-height: 16px;
} */

.label-18 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
}

.label-20 {
  font-style: normal;
  font-size: 20px;
  font-weight: 600;
}

.box {
  background: linear-gradient(90deg, #FCEAEA 0%, #EAFCF0 100%);
  box-shadow: none;
  height: auto;
  border-radius: 0;
}

.box img {
  width: auto;
  height: 120px;
}

.btn-lengkapi {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.25%);
  color: #ffffff;
}

.modal-preview {
  background-color: rgba(0, 0, 0, 0.2);
}

.content-modal {
  width: 100%;
}

.modal-header {
  background: #911a1e;
  padding: 24px;
  color: white;
  border-radius: 12px 12px 0px 0px;
}

.modal-body {
  background: white;
  border-radius: 0px 0px 12px 12px;
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.wrap-preparasi {
  background: linear-gradient(90deg, #FCEAEA 0%, #EAFCF0 100%);
  box-shadow: none;
  height: 25%;
  border-radius: 0;
}

.wrap-offering-preparasi {
  height: 65%;
}

.wrap-footer-preparasi {
  height: 10%;
  background: white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.wrap

.wrap-input {
  width: 270px;
}

.wrap-input input {
  border: none;
  outline: none;
  padding: 10px 18px;
  font-family: 'Averta-Bold';
}

.wrap-input input[type=number]::-webkit-inner-spin-button,
.wrap-input input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.wrap-detail-penawaran {
  height: 50vh;
  overflow-y: auto;
  scroll-padding-top: 190px;
}

.content-collapse {
  background: white;
  border: 1px solid #E1E1E1;
  padding: 2px;
  border-radius: 12px;
}

.content-collapse:not(:last-child) {
  margin-bottom: 28px;
}

.wrap-button {
  background: #D9272D;
  padding: 10px 10px;
  border: 1px solid var(--gradient-red-red-primary-button, #D9272D);
  color: white;
  border-radius: 24px;
  cursor: pointer;
}

.tag-redirect {
  background: #FAFAFA;
  width: max-content;
  height: 36px;
  border-radius: 100px;
  border: 1px solid #E1E1E1;
  padding: 8px 12px;
}

.tag-redirect.is-disabled {
  cursor: not-allowed;
}

</style>
