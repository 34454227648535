<template>
  <div class="mt-4 wrapper-survey px-5 pt-5 pb-6 my-6">
    <div>
      <p class="averta-bold label-20">Survey Berlangsung</p>
      <p>Selesaikan survey yang sedang berlangsung hari ini</p>
    </div>
    <div class="columns mt-3 parrent-list pb-3">
      <div class="column card list-survey is-4 p-5 m-2 is-clickable is-flex is-flex-direction-column" v-for="data, index in dataSurveyBerlangsung" :key="`survey-berlangsung-${index}`" @click="goToProject(data.id)">
        <div class="columns card-atas pb-2 is-flex-grow-1 mb-0">
            <div class="column">
                <p>{{ data.survey_complain_code ? data.survey_complain_code : data.id }}</p>
                <p class="store averta-bold">{{ data.branch.name }}</p>
            </div>
            <div class="column has-text-centered">
                <p class="px-3 py-1 status-survey mb-3">{{ data.status }}</p>
                <div class="is-flex">
                  <div class="img-vendor mr-2">
                    <img :src="data.vendor.logo" :alt="data.vendor.name">
                  </div>
                  <p class="averta-bold">{{ data.vendor.name }}</p>
                </div>
            </div>
        </div>
        <div class="columns card-bawah mt-4">
            <div class="column">
                <p class="mb-2">Konsultan (Survey)</p>
                <p class="averta-bold">{{ data.consultant.name }}</p>
                <p class="store"><b-icon icon="whatsapp" custom-size="default"/>+62{{ data.consultant.phone }}</p>
            </div>
            <div class="column">
                <p class="mb-2">Tanggal Survey</p>
                <p class="averta-bold">{{ convertDate(data.date) }}</p>
                <p>{{ data.start_time }} - {{ data.end_time }}</p>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'CardSurveryBerlangsungDashboard',
  props: ['dataSurveyBerlangsung'],
  computed: {
    currentPath () {
      const path = this.$route.path.split('/')
      console.log(path)
      const firstElement = path[1]

      return firstElement
    }
  },
  methods: {
    convertDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    goToProject (id) {
      if (this.currentPath === 'am') {
        this.$router.push('/am/project')
      } else if (this.currentPath === 'sm') {
        this.$router.push(`/sm/project/survey/${id}/detail`)
      } else if (this.currentPath === 'purchasing') {
        this.$router.push('/purchasing/project')
      } else {
        this.$router.push(`/project/survey/${id}/detail`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper-survey {
    background: #FAFAFA;
}
.card {
  box-shadow: none;
}

.store {
    color: #D9272D;
}

.status-survey {
    background: #3185FC;
    border-radius: 20px;
    color: white;
}

.card-atas {
    border-bottom: 2px dashed #868686;
}

.card-bawah {
    background: linear-gradient(90deg, rgba(255, 221, 221, 0.4) 0%, rgba(255, 239, 219, 0.4) 99.97%);
    border-radius: 12px;
}

.parrent-list {
    overflow-x: auto;
}
.list-survey {
    background: white;
}
</style>
