<template>
    <div class="wrap-lampiran">
        <p class="averta-bold label-20 mb-3">Berkas Lampiran</p>
        <div class="wrap-spk-sik is-flex is-justify-content-space-between">
          <div v-if="dataAttachment.length > 0" class="c-w-100 is-flex is-justify-content-space-between">
            <!-- SIK -->
            <div
              v-for="(item, index) in dataAttachment"
              :key="index"
              class="card-lampiran is-flex is-justify-content-space-between is-align-items-center"
            >
                <div class="is-flex is-align-items-center">
                  <PaperIcon class="mr-2" />
                  <div>
                    <p class="label-14 averta-bold">{{ item.name || '-' }}</p>
                    <p class="label-12 text-grey">upload pada : {{  item.updated_at || '-' }}</p>
                  </div>
                </div>

                <div>
                  <div
                    :ref="`dropdown${index}`"
                    class="dot is-clickable"
                    @click="showDropdownAttachment(item.type, index)"
                  ></div>
                  <div
                    v-if="index === 0"
                    v-closable="{
                      exclude: [`dropdown${index}`],
                      handler: () => clickOutsideDropdown(index, item.type)
                    }"
                  >
                    <DropdownAttachment
                      :surveyId="surveyId"
                      :type="item.type"
                      :dataAttachment="item"
                      :dataHistory="dataHistoryAttachment"
                      :showDropdown="item.type === 'SIK' ? showSIK : showSPK"
                      :refreshData="getBerkasLampiran"
                      :getHistory="getHistoryAttachment"
                      @closeDropdown="closeDropdown"
                    />
                  </div>
                  <div
                    v-else-if="index === 1"
                    v-closable3="{
                      exclude: [`dropdown${index}`],
                      handler: () => clickOutsideDropdown(index, item.type)
                    }"
                  >
                    <DropdownAttachment
                      :surveyId="surveyId"
                      :type="item.type"
                      :dataAttachment="item"
                      :dataHistory="dataHistoryAttachment"
                      :showDropdown="item.type === 'SIK' ? showSIK : showSPK"
                      :refreshData="getBerkasLampiran"
                      :getHistory="getHistoryAttachment"
                      @closeDropdown="closeDropdown"
                    />
                  </div>
                </div>
                <!-- <div class="wrap-action is-flex is-align-items-center is-justify-content-space-between">
                    <div class="wrap-icon is-flex is-align-items-center" @click="download(dataSIK.filename, dataSIK.name)">
                        <DownloadIcon class="mr-2" />
                        <p class="text-merah averta-bold label-14">Unduh</p>
                    </div>
                    <div class="wrap-icon is-flex is-align-items-center" @click="previewLampiranSIK">
                        <EyeIcon class="mr-2" />
                        <p class="text-merah averta-bold label-14">Lihat</p>
                    </div>
                </div> -->
            </div>
          </div>
        </div>

        <!-- Modal Preview Image -->
        <ModalPreviewImage
          :isActive="modalPreviewImageActive"
          :dataImage="imagePreview"
          :title="titlePreviewImage"
          @closeModal="closeModalPreviewImage()"
        />
        <!-- Modal Preview Image -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage } from '@/helpers'
import { PaperIcon, DownloadIcon, EyeIcon } from '@/components/icons'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'
import DropdownAttachment from '@/components/dropdown/DropdownAttachment.vue'

export default {
  name: 'TabBerkasLampiran',
  props: {
    surveyId: {
      type: [String, Number]
    }
  },
  components: {
    PaperIcon,
    // eslint-disable-next-line vue/no-unused-components
    DownloadIcon,
    // eslint-disable-next-line vue/no-unused-components
    EyeIcon,
    ModalPreviewImage,
    DropdownAttachment
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      isLoading: false,
      dataAttachment: [],
      showSIK: false,
      showSPK: false,
      // showSIK: [],
      // showSPK: [],
      tempType: null,
      modalPreviewImageActive: false,
      imagePreview: null,
      titlePreviewImage: null,
      filterRefs: [],
      dataHistoryAttachment: []
    }
  },
  methods: {
    setFilterRef (index) {
      return (el) => {
        this.$set(this.filterRefs, index, el) // Store the DOM element
      }
    },
    previewLampiranSIK () {
      this.imagePreview = [this.dataSIK.filename]
      this.titlePreviewImage = 'Lampiran SIK'
      this.modalPreviewImageActive = true
    },
    previewLampiranSPK () {
      this.imagePreview = [this.dataSPK.filename]
      this.titlePreviewImage = 'Lampiran SPK'
      this.modalPreviewImageActive = true
    },
    closeModalPreviewImage () {
      this.modalPreviewImageActive = false
    },
    getBerkasLampiran () {
      this.isLoading = true

      const payload = {
        url: this.url,
        proyek_id: this.$route.params.proyekId,
        category: 'detail',
        subcategory: 'attachments'
      }

      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then(res => {
          this.isLoading = false
          this.dataAttachment = res.data.data.attachments
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    getHistoryAttachment () {
      const payload = {
        url: this.url,
        survey_id: this.surveyId
      }

      this.$store
        .dispatch('project/getHistoryAttachment', payload)
        .then(res => {
          this.dataHistoryAttachment = res.data.data
        })
        .catch(error => {
          alertErrorMessage(error)
        })
    },
    showDropdownAttachment (type, index) {
      if (type === 'SIK') {
        this.showSIK = !this.showSIK
        this.showSPK = false
      } else if (type === 'SPK') {
        this.showSPK = !this.showSPK
        this.showSIK = false
      }
    },
    closeDropdown () {
      this.showSIK = false
      this.showSPK = false
    },
    clickOutsideDropdown (index, type) {
      if (type === 'SIK') {
        this.showSIK = false
      } else if (type === 'SPK') {
        this.showSPK = false
      }
    }
  },
  created () {
    this.getBerkasLampiran()
  },
  mounted () {
    this.getHistoryAttachment()
  }
}
</script>

<style scoped>
.wrap-lampiran {
    width: 100%;
    height: 100%;
    border: 1px solid #D8D8D8;
    border-radius: 12px;
    padding: 18px;

    /* remove this latter */
    margin-bottom: 40vh;
}

.card-lampiran {
    width: 49%;
    background: #FAFAFA;
    border: none;
    padding: 12px;
    border-radius: 20px;
}

.wrap-action {
    width: 34%;
}

.wrap-icon {
    border: 2px solid #E10009;
    border-radius: 100px;
    padding: 6px 14px;
    cursor: pointer;
}

.dot {
  padding: 0.7em;
  margin: -0.7em;
}

.dot::after {
  content: '\2807';
  font-size: 20px;
  color: #868686;
}

@media screen and (max-width: 768px) {
    .wrap-spk-sik {
        flex-direction: column;
    }

    .card-lampiran {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .card-lampiran:first-child {
        margin-bottom: 14px;
    }

    .wrap-action {
        width: auto;
        margin-top: 12px;
    }

    .wrap-icon:first-child {
        margin-right: 12px;
    }
}
</style>
