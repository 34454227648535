<template>
    <div class="wrap-card border-card mb-5">
        <!-- Highlight Card Invoice for Search Global  -->
        <div v-if="dataHighlight(data, 'invoice') === true" class="highlight-invoice is-flex is-align-items-center is-justify-content-space-between">
            <div class="is-flex align-items-center mr-4">
                <div class="border-right">
                    <p class="label-14 text-grey">No. Invoice</p>
                    <p class="label-18 hightlight averta-bold px-2">{{ data.invoice }}</p>
                </div>
                <div class="pl-4">
                    <p class="label-14 text-grey">Jatuh Tempo</p>
                    <div>
                        <div class="is-flex is-align-items-center">
                            <LoncengIcon class="mr-2" />
                            <p class="averta-bold text-merah label-12 white-space-nowrap">{{ convertDate(data.invoice_due_date) }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="status-survey px-3 mt-sm-1 white-space-nowrap" :class="getClassName(data.status.status_name)">
                <span>{{ data.status.status_name}}</span>
            </div>
        </div>
        <!-- Highlight Card Invoice for Search Global  -->

        <!-- Header Card -->
        <div class="header-card">
            <!-- Logo Client and Status For Mobile Only -->
            <div class="wrap-logo-status-mobile mb-3">
                <div class="is-flex is-align-items-center is-justify-content-space-between align-items-sm-start">
                    <div class="mr-4">
                        <div class="is-flex is-align-items-center is-justify-content-center">
                            <img :src="data.vendor.logo" :alt="data.vendor.name" style="max-width: 140px;">
                        </div>
                        <!-- <p class="label-14 averta-bold has-text-centered white-space-nowrap" :class="{'hightlight' : dataHighlight(data, 'vendor_name')}">{{ data.vendor.name }}</p> -->
                    </div>
                    <div class="is-flex is-align-items-center is-justify-content-center flex-column">
                        <div class="status-survey px-3 py-1" :class="getClassName(data.status.status_name)">
                            <span class="white-space-sm-normal has-text-centered">{{ wordingHelper(data.status.status_name) }}</span>
                        </div>
                        <div v-if="data.is_reschedule === 1 || data.is_extend === 1" class="is-flex is-align-items-center" style="margin-left: -16px;">
                            <div v-if="showTagRescheduleExtend(data.is_reschedule)" class="wrap-tag-reschedule-extends mt-2 is-flex is-align-items-center is-justify-content-center">
                                <span>Reschedule</span>
                            </div>
                            <div v-if="showTagRescheduleExtend(data.is_extend)" class="wrap-tag-reschedule-extends mt-2 is-flex is-align-items-center is-justify-content-center">
                                <span>Extend</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Logo Client and Status For Mobile Only -->

            <!-- Detail ID, Logo Client, and Status Proyek -->

            <!-- Detail ID -->
            <div class="is-flex is-justify-content-space-between overflow-sm-auto thin-scroll">
                <div class="border-right">
                    <p class="averta-bold label-14 white-space-nowrap">ID</p>
                    <p class="label-14 text-grey id-survey" :class="{'hightlight' : dataHighlight(data, 'survey_id', 'survey_code')}">{{ data.survey_complain.survey_code || data.survey_id }}</p>
                    <div
                        v-if="data.survey_complain.type === 'parent' && data.survey_complain.total_survey_complain > 0"
                        class="flex-center mt-1 bg-white px-3 py-1 border-radius-100 bg-red"
                        :class="{'cursor-pointer': isSM || isBS}"
                        @click="redirectToProjectParent(data)"
                    >
                        <p class="color-white averta-bold mr-1">{{isSM || isBS ? data.survey_complain.total_survey_complain : ''}} Komplain</p>
                        <ArrowRightIcon v-if="isBS || isSM" color="#fff" width="13" height="13" />
                    </div>
                    <div
                        v-else-if="data.survey_complain.type === 'child' && data.survey_complain.survey_code"
                        class="flex-center mt-1 bg-white px-3 py-1 border-radius-100"
                        :class="{'cursor-pointer': isSM || isBS}"
                        @click="redirectToProjectParent(data)"
                    >
                        <TagRedirect :color="!isBS && !isSM && '#868686'"/>
                        <p
                            class="averta-bold mx-1"
                            :class="{
                                'color-red': isSM || isBS,
                                'color-grey': !isSM && !isBS,
                                'hightlight' : dataHighlight(data, 'survey_parent_id')
                            }"
                        >
                            {{data.survey_complain.survey_parent_id}}
                        </p>
                        <ArrowRightIcon v-if="isBS || isSM" color="#E10009" width="13" height="13" />
                    </div>
                </div>
                <div class="pl-4 border-right">
                    <p class="averta-bold label-14 white-space-nowrap">ID (<i class="label-12">Admin Panel</i>)</p>
                    <p class="label-14 text-grey" :class="{'hightlight' : dataHighlight(data, 'survey_homecare_id')}">{{ data.survey_homecare_id }}</p>
                </div>
                <!-- <div class="pl-4 border-right">
                    <p class="averta-bold label-14 white-space-nowrap">ID Penawaran</p>
                    <p class="label-14 text-grey" :class="{'hightlight' : dataHighlight(data, 'offering_id')}">{{ data.offering_id ? `${data.offering_id}` : '-' }}</p>
                    <p class="label-10 averta-bold white-space-nowrap"><i>Belum Dikonfirmasi</i></p>
                    <p class="label-10 averta-bold white-space-nowrap has-text-success"><i>Sudah Dikonfirmasi</i></p>
                </div> -->
                <!-- <div class="pl-4 border-right">
                    <p class="averta-bold label-14 white-space-nowrap">ID Proyek</p>
                    <p class="label-14 text-grey" :class="{'hightlight' : dataHighlight(data, 'project_id')}">{{ data.project_id ? `${data.project_id}` : '-' }}</p>
                </div> -->
                <div class="pl-4 border-right">
                    <p class="averta-bold label-14 white-space-nowrap">ID Proyek <br /> (<i class="label-12">Admin Panel</i>)</p>
                    <p class="label-14 text-grey" :class="{'hightlight' : dataHighlight(data, 'project_homecare_id')}">{{ data.project_homecare_id ? `${data.project_homecare_id}` : '-' }}</p>
                </div>
                <div class="pl-4 border-right">
                    <p class="averta-bold label-14 white-space-nowrap">ID Admin Panel <br /> (<i class="label-12">Proyek Darurat</i>)</p>
                    <div class="is-flex is-align-items-center">
                        <p class="label-14 averta-bold white-space-nowrap mr-2" v-if="data.project_urgent_id && data.project_urgent_id.length > 0">
                            <span>{{ data.project_urgent_id[0] }}; </span>
                            <span v-if="data.project_urgent_id[1]">{{ data.project_urgent_id[1] }}</span>
                        </p>
                        <p class="label-12 averta-bold white-space-nowrap mr-2" v-else>
                            <span v-if="user.roles[0].name === 'Account Manager'" class="text-merah is-clickable" @click="showModalProyekDarurat(data.project_urgent_id, data.survey_id)">Belum diatur</span>
                            <span v-else>-</span>
                        </p>
                        <div class="is-flex is-align-items-center is-justify-content-center is-clickable" @click="showModalProyekDarurat(data.project_urgent_id, data.survey_id)">
                            <div v-if="user.roles[0].name === 'Account Manager'">
                                <img :src="require('@/assets/img/icon-edit-merah.png')" width="20" class="mr-1">
                            </div>
                            <p class="white-space-nowrap text-merah averta-bold" v-if="data.project_urgent_id && data.project_urgent_id.length > 2"><u>{{ data.project_urgent_id.length - 2 }} Lainnya</u></p>
                        </div>
                    </div>
                </div>
                <div class="pl-4 border-right">
                    <p class="averta-bold label-14 white-space-nowrap">Penawaran <br /> Terkirim</p>
                    <p class="averta-bold label-14 text-grey">{{ convertDate(data.offering_sent_date) }}</p>
                </div>
                <div class="px-4">
                    <p class="averta-bold label-14 white-space-nowrap">Tanggal <br /> Pembayaran</p>
                    <p class="averta-black label-14" :class="{'text-grey' : !data.paid_date, 'has-text-success' : data.paid_date}">{{ convertDate(data.paid_date) }}</p>
                </div>
            </div>
            <!-- Detail ID -->

            <!-- Status Web Only -->
            <div class="wrap-logo-status is-flex is-align-items-center is-justify-content-center">
                <div class="is-flex is-align-items-center">
                    <div class="status-survey px-3 mt-sm-1 white-space-nowrap" :class="getClassName(data.status.status_name)">
                        <span>{{ wordingHelper(data.status.status_name) }}</span>
                    </div>
                    <div v-if="data.is_reschedule === 1 || data.is_extend === 1" class="is-flex is-align-items-center">
                        <div v-if="showTagRescheduleExtend(data.is_reschedule)" class="wrap-tag-reschedule-extends is-flex is-align-items-center is-justify-content-center">
                            <span>Reschedule</span>
                        </div>
                        <div v-if="showTagRescheduleExtend(data.is_extend)" class="wrap-tag-reschedule-extends is-flex is-align-items-center is-justify-content-center">
                            <span>Extend</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Status Web Only -->

            <!-- Detail ID, Client, and Status Proyek -->
        </div>
        <!-- Header Card -->

        <!-- Body Card -->
        <div class="body-card">
            <!-- Section Detail Project -->
            <div class="section-detail-project is-flex is-justify-content-space-between px-5 py-4">
                <!-- Wrap Store -->
                <div class="wrap-store mr-4">
                    <!-- Logo Client For Web Only -->
                    <div class="wrap-logo-status mb-2">
                        <img :src="data.vendor.logo" :alt="data.vendor.name" class="wrap-logo">
                        <!-- <p class="label-14 averta-bold" :class="{'hightlight' : dataHighlight(data, 'vendor_name')}">{{ data.vendor.name }}</p> -->
                    </div>
                    <!-- Logo Client For Web Only -->

                    <!-- <p>Toko</p> -->
                    <p class="averta-bold label-14" :class="{'hightlight' : dataHighlight(data, 'store_name')}">{{ data.vendor.store_name }}</p>

                    <!-- Store Name -->
                    <div class="is-flex is-align-items-center">
                        <b-tooltip position="is-top" type="is-dark" :triggers="['hover']" :auto-close="true">
                            <template v-slot:content>
                                <div class="wrap-tooltip is-flex is-align-items-center svg-tooltip">
                                    <img :src="require('@/assets/img/icon-map.png')" width="20" class="mr-1">
                                    <span class="ml-3">
                                        {{ data.vendor.store_address }}
                                    </span>
                                </div>
                            </template>
                            <p class="label-12 address mr-1">{{ data.vendor.store_address }}</p>
                        </b-tooltip>
                        <b-tooltip position="is-right" type="is-dark" :triggers="['hover']" :auto-close="true">
                            <template v-slot:content>
                                <div class="is-flex is-align-items-center" style="width: 150px;">
                                    <img :src="require('@/assets/img/copy-white.png')" width="20" class="mr-1">
                                    <span class="ml-3">
                                        Copy Informasi
                                    </span>
                                </div>
                            </template>
                            <img :src="require('@/assets/img/copy-icon-2.png')" width="14" class="mr-1 is-clickable img-copy" @click="copyToClipboard(data.vendor.store_address)">
                        </b-tooltip>
                    </div>
                    <!-- Store Name -->

                    <!-- PIC Project -->
                    <div class="is-flex is-align-items-center">
                        <b-tooltip position="is-bottom" type="is-dark" :triggers="['hover']" :auto-close="true">
                            <template v-slot:content>
                                <div class="wrap-tooltip pic-tooltip">
                                    <p>{{ data.pic.name }}</p>
                                    <p>+{{ data.pic.phone }}</p>
                                </div>
                            </template>
                            <p class="white-space-nowrap mr-1"><i>PIC Proyek</i> :
                                <span class="averta-bold pic-wrap">{{ data.pic.name }}</span>
                                <!-- <span class="text-merah">+{{ data.pic.phone }}</span> -->
                            </p>
                        </b-tooltip>
                        <b-tooltip position="is-right" type="is-dark" :triggers="['hover']" :auto-close="true">
                            <template v-slot:content>
                                <div class="is-flex is-align-items-center" style="width: 150px;">
                                    <img :src="require('@/assets/img/copy-white.png')" width="20" class="mr-1">
                                    <span class="ml-3">
                                        Copy Informasi
                                    </span>
                                </div>
                            </template>
                            <img :src="require('@/assets/img/copy-icon-2.png')" width="14" class="mr-1 is-clickable img-copy" @click="copyToClipboard(`PIC Proyek : ${data.pic.name} (+${data.pic.phone})`)">
                        </b-tooltip>
                    </div>
                    <!-- PIC Project -->

                </div>
                <!-- Wrap Store -->

                <!-- Wrap Consultan -->
                <div class="wrap-consultan mr-4">
                    <!-- <p>Konsultan</p> -->
                    <div>
                        <p>Konsultan Survey</p>
                        <p class="averta-bold pic-wrap">{{ data.consultan_survey.name ? data.consultan_survey.name : '-' }}</p>
                        <div class="is-flex is-align-items-center" v-if="data.consultan_survey.name">
                            <p class="text-merah mr-1   ">(+62{{ data.consultan_survey.phone }})</p>
                            <b-tooltip position="is-right" type="is-dark" :triggers="['hover']" :auto-close="true">
                                <template v-slot:content>
                                    <div class="is-flex is-align-items-center" style="width: 150px;">
                                        <img :src="require('@/assets/img/copy-white.png')" width="20" class="mr-1">
                                        <span class="ml-3">
                                            Copy Informasi
                                        </span>
                                    </div>
                                </template>
                                <img :src="require('@/assets/img/copy-icon-2.png')" width="14" class="mr-1 is-clickable img-copy" @click="copyToClipboard(`+62${data.consultan_survey.phone}`)">
                            </b-tooltip>
                        </div>
                    </div>
                    <!-- <div class="is-flex is-align-items-center">
                        <b-tooltip position="is-bottom" type="is-dark" :triggers="['hover']" :auto-close="true">
                            <template v-slot:content>
                                <div class="wrap-tooltip pic-tooltip">
                                    <p>{{ data.consultan_supervisor.name }}</p>
                                    <p>+62{{ data.consultan_supervisor.phone }}</p>
                                </div>
                            </template>
                            <p class="white-space-nowrap mr-1">Pengawas : <span class="averta-bold pic-wrap">{{ data.consultan_supervisor.name }}</span><span class="text-merah">+62{{ data.consultan_supervisor.phone }}</span></p>
                        </b-tooltip>
                        <b-tooltip position="is-right" type="is-dark" :triggers="['hover']" :auto-close="true">
                            <template v-slot:content>
                                <div class="is-flex is-align-items-center" style="width: 150px;">
                                    <img :src="require('@/assets/img/copy-white.png')" width="20" class="mr-1">
                                    <span class="ml-3">
                                        Copy Informasi
                                    </span>
                                </div>
                            </template>
                            <img :src="require('@/assets/img/copy-icon-2.png')" width="14" class="mr-1 is-clickable img-copy" @click="copyToClipboard(`Konsultan Pengawas: ${data.consultan_supervisor.name} (+62${data.consultan_supervisor.phone})`)">
                        </b-tooltip>
                    </div> -->
                </div>
                <!-- Wrap Consultan -->

                <!-- Wrap Date Survey -->
                <div class="wrap-date-survey mr-4">
                    <p>Tanggal Survey</p>
                    <p class="averta-bold white-space-nowrap">{{ convertDate(data.survey.start_date_survey) }}</p>
                    <p class="text-grey white-space-nowrap" v-if="data.survey.start_time_survey && data.survey.end_time_survey">({{ data.survey.start_time_survey.substring(0, 5) }} - {{ data.survey.end_time_survey.substring(0, 5) }})</p>
                </div>
                <!-- Wrap Date Survey -->

                <!-- Wrap Date Project -->
                <div class="wrap-date-project mr-4">
                    <p>Tanggal Proyek</p>
                    <p class="white-space-nowrap">Mulai : <span class="averta-bold">{{ convertConfirmation(data.start_date_project) }}</span></p>
                    <p class="white-space-nowrap">Selesai : <span class="averta-bold">{{ convertConfirmation(data.end_date_project) }}</span></p>
                </div>
                <!-- Wrap Date Project -->

                <!-- Wrap Invoice -->
                <div v-if="user.roles[0].name === 'Account Manager'" class="wrap-invoice">
                    <p class="white-space-nowrap">Invoice Penagihan</p>
                    <div class="is-flex is-align-items-center mb-1">
                        <img :src="require('@/assets/img/icon-invoice.png')" width="24" class="mr-1">
                        <b-tooltip v-if="data.invoice" position="is-bottom" type="is-dark" :triggers="['hover']" :auto-close="['outside']">
                            <template v-slot:content>
                                <p class="white-space-nowrap averta-bold mr-2">{{ data.invoice }}</p>
                            </template>
                            <p class="white-space-nowrap averta-bold mr-2 invoice-number" :class="{'text-merah': data.project_id, 'text-grey': !data.project_id}">{{ data.invoice }}</p>
                        </b-tooltip>
                        <p v-else @click="showModalInvoiceTagihan({noInvoice: data.invoice, projectId: data.project_id})" class="white-space-nowrap averta-bold mr-2 is-clickable" :class="{'text-merah': data.project_id, 'text-grey': !data.project_id}">Belum diatur</p>
                        <div @click="showModalInvoiceTagihan({noInvoice: data.invoice, projectId: data.project_id})" class="is-clickable">
                            <img :src="require(`@/assets/img/icon-edit-${data.project_id ? 'merah' : 'abu'}.png`)" width="20">
                        </div>
                    </div>
                    <div class="is-flex is-align-items-center mb-1">
                        <img
                            :src="require('@/assets/img/icon-tanggal.png')"
                            width="24"
                            class="mr-1"
                        >
                        <p
                            v-if="data.invoice_due_date"
                            class="white-space-nowrap mr-2 averta-bold"
                            :class="{'text-merah': data.project_id, 'text-grey': !data.project_id}"
                        >
                            {{ convertConfirmation(data.invoice_due_date) }}
                        </p>
                        <p
                            v-else
                            class="white-space-nowrap mr-2 averta-bold is-clickable"
                            :class="{'text-merah': data.project_id, 'text-grey': !data.project_id}"
                            @click="showModalTanggalPenagihan({tanggalPenagihan: data.invoice_due_date, projectId: data.project_id})"
                        >
                            Belum diatur
                        </p>
                        <div
                            @click="showModalTanggalPenagihan({tanggalPenagihan: data.invoice_due_date, projectId: data.project_id})"
                            class="is-clickable"
                        >
                            <img
                                :src="require(`@/assets/img/icon-edit-${data.project_id ? 'merah' : 'abu'}.png`)"
                                width="20"
                            >
                        </div>
                    </div>
                </div>
                <!-- Wrap Invoice -->

                <!-- Wrap Grand Total -->
                <div v-if="user.roles[0].name === 'Account Manager'" class="wrap-grand-total">
                    <div>
                        <p class="averta-bold">Grand Total Penawaran</p>
                        <p class="averta-black has-text-right text-merah label-14">{{ formatThousand(data.grand_total) }}</p>
                    </div>
                </div>
                <!-- Wrap Grand Total -->

                <!-- Wrap Detail -->
                <div class="wrap-detail">
                    <a
                        class="button-detail is-clickable"
                        :class="{'isMobile' : isMobile}"
                        :href="detailLink"
                        @click="detailProject($event, data)"
                    >
                        <div class="averta-bold" v-if="isMobile">Lihat Detail</div>
                        <ArrowRightIcon color="#D9272D" v-else />
                    </a>
                </div>
                <!-- Wrap Detail -->
            </div>
            <!-- Section Detail Project -->

            <!-- Material For Purchasing -->
            <div class="wrapper-material p-5" v-if="user.roles[0].name === 'Purchasing' && data.project_id && data.material">
                <div class="is-flex is-justify-content-space-between is-align-items-center">
                    <div class="white-space-nowrap card-estimasi p-3">
                        <p>Jumlah Material Estimasi</p>
                        <p><span class="averta-bold">{{ data.material.total_estimate }}</span> Material</p>
                    </div>
                    <div class="columns">
                        <div class="column white-space-nowrap card-material border-left-red">
                            <p>Qty Pemesanan Material</p>
                            <p><span class="text-merah averta-bold">{{ data.material.total_process }}</span> <span class="has-text-grey">Material</span></p>
                        </div>
                        <div class="column white-space-nowrap card-material border-left-yellow">
                            <p>Qty Pembelian Material</p>
                            <p><span class="text-merah averta-bold">{{ data.material.total_ordered }}</span> <span class="has-text-grey">Material</span></p>
                        </div>
                        <div class="column white-space-nowrap card-material border-left-green">
                            <p>Qty Material Terkirim</p>
                            <p><span class="text-merah averta-bold">{{ data.material.total_delivered }}</span> <span class="has-text-grey">Material</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Material For Purchasing -->

            <!-- Section Konfirmasi Project -->
            <div class="wrapper-confirmation" v-if="user.roles[0].name !== 'Purchasing' && data.project_confirmation">
                <!-- Status Konfirmasi Tukang Kanggo, PIC Outlet, PIC Cabang -->
                <div class="p-5">
                    <div class="is-flex is-justify-content-space-between flex-sm-column">
                        <div>
                            <p class="averta-bold">Status Konfirmasi Proyek Selesai</p>
                            <p>
                                <span class="has-text-danger averta-bold">{{ countingConfirmation(data.project_confirmation) }}</span>
                                <span class="ml-1">Konfirmasi</span>
                            </p>
                        </div>
                        <div class="is-flex is-align-items-center flex-sm-column">
                            <div class="mr-4 is-flex border-radius-10 flex-sm-column mt-sm-2 mb-sm-2">
                                <div v-for="confirmation, index in data.project_confirmation" :key="`${index}-confirmation`" class="wrapper-confirmation-item is-flex is-align-items-center border-sm-none">
                                    <!-- Check or Uncheck -->
                                        <CheckIcon v-if="confirmation.created_at && confirmation.created_at !== null" color="#10BD41" class="mr-3" />
                                        <div v-else class="unchecked mr-3"></div>
                                    <!-- Check or Uncheck -->

                                    <!-- Detail Confirmation -->
                                    <div>
                                        <p class="averta-bold">{{ confirmation.label }}</p>

                                        <!-- Confirmation Tukang Kanggo -->
                                        <p class="text-confirmation is-size-7 averta-bold" v-if="confirmation.label === 'Tukang Kanggo' && confirmation.created_at && confirmation.created_at !== null">
                                            Sudah Konfirmasi Selesai - {{ convertConfirmation(confirmation.created_at) }}
                                        </p>
                                        <p class="text-phone is-italic is-size-7" v-if="confirmation.label === 'Tukang Kanggo' && !confirmation.created_at">
                                            Dalam pengerjaan tukang
                                        </p>
                                        <!-- Confirmation Tukang Kanggo -->

                                        <!-- Confirmation PIC Outlet -->
                                        <p class="text-confirmation is-size-7 averta-bold" v-if="confirmation.label === 'PIC Outlet' && confirmation.created_at && confirmation.created_at !== null">
                                            Sudah Konfirmasi Selesai - {{ convertConfirmation(confirmation.created_at) }}
                                        </p>
                                        <p class="text-phone is-italic is-size-7" v-if="confirmation.label === 'PIC Outlet' && !confirmation.created_at">
                                            Menunggu pekerjaan selesai
                                        </p>
                                        <!-- Confirmation PIC Outlet -->

                                        <!-- Confirmation PIC Cabang -->
                                        <p class="text-confirmation is-size-7 averta-bold" v-if="confirmation.label === 'PIC Cabang' && confirmation.created_at && confirmation.created_at !== null">
                                            Sudah Konfirmasi Selesai - {{ convertConfirmation(confirmation.created_at) }}
                                        </p>
                                        <p class="text-phone is-italic is-size-7" v-if="confirmation.label === 'PIC Cabang' && !confirmation.created_at">
                                            Menunggu pekerjaan selesai
                                        </p>
                                        <!-- Confirmation PIC Cabang -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Status Konfirmasi Tukang Kanggo, PIC Outlet, PIC Cabang -->

                <!-- Detail Status Pekerjaan Tukang -->
                <div class="px-5 pb-5" v-if="data.status_worker">
                    <b-collapse class="has-background-white border-collapse" animation="slide" aria-id="contentIdForA11y3"
                        :open="false">
                        <template #trigger="props">
                            <div class="card-header" role="button" aria-controls="contentIdForA11y3"
                                :aria-expanded="props.open">
                                <div class="card-header-title is-block">
                                    <p>Detail Status Pekerjaan Tukang</p>
                                    <p>
                                        <span class="has-text-danger">{{ confirmationWorker(data.status_worker) }}</span>
                                        <span class="has-text-grey ml-1">Konfirmasi</span>
                                    </p>
                                </div>
                                <a class="card-header-icon">
                                    <b-icon :icon="props.open ? 'menu-up' : 'menu-down'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>

                        <div class="card-content">
                            <div class="content wrapper-list-worker">
                                <div v-for="confirmationWorker, index in data.status_worker" :key="`${index}-confirmationWorker`" class="is-flex is-align-items-center is-justify-content-space-between" :class="{'mb-5': index + 1 !== data.status_worker.length}">
                                    <div class="is-flex is-align-items-center">
                                        <!-- Status Check or Uncheck Worker -->
                                        <div v-if="confirmationWorker.checked !== true" class="unchecked mr-3"></div>
                                        <CheckIcon v-else color="#10BD41" class="mr-3" />
                                        <!-- Status Check or Uncheck Worker -->

                                        <div>
                                            <p class="averta-bold mb-0">
                                                {{ confirmationWorker.skill_name }}
                                            </p>
                                            <p class="text-phone is-italic" v-if="confirmationWorker.checked !== true">Dalam pengerjaan tukang</p>
                                            <p class="text-confirmation averta-bold" v-else>Sudah Konfirmasi Selesai</p>
                                        </div>
                                    </div>
                                    <div class="is-flex is-align-items-center">
                                        <img :src="imageBefore.image_url" class="image-upload mr-3" v-for="imageBefore, index in confirmationWorker.photo_befores.slice(0, 2)" :key="`${index}-imageBefore`" />
                                        <img :src="imageAfter.image_url" class="image-upload mr-3" v-for="imageAfter, index in confirmationWorker.photo_afters.slice(0, 2)" :key="`${index}-imageAfter`" />

                                        <button v-if="confirmationWorker.photo_befores.length > 0 || confirmationWorker.photo_afters.length > 0" class="btn-lihat-detail" @click="showModalBeforeAfter(confirmationWorker)">
                                            Lihat Foto
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                </div>
                <!-- Detail Status Pekerjaan Tukang -->
            </div>
            <!-- Section Konfirmasi Project -->

            <!-- Section Catatan Proyek -->
            <div class="wrap-catatan-proyek px-5 py-4">
                <div class="wrap-catatan-proyek-title mb-3">
                    <p>Catatan Proyek</p>
                    <p>Terakhir Diubah : <span class="averta-bold">{{ lastUpdateNote(data.last_project_note.by, data.last_project_note.date) }}</span></p>
                </div>
                <div class="mb-3">
                    <textarea v-model="catatanProyek" class="catatan-proyek" :style="{ 'max-height': maxHeight }" placeholder="Tambah Catatan Untuk Proyek Ini" :rows="calculateRows(catatanProyek)" @input="autoExpand($event)"></textarea>
                </div>
                <div class="is-flex is-align-items-center is-justify-content-space-between">
                    <button class="btn-history-catatan active averta-bold label-14 is-clickable" @click="lihatCatatanProyek">Lihat Riwayat Catatan</button>
                    <button class="btn-simpan averta-bold label-14 is-clickable" :class="{ 'active': isChangeInput }" @click="saveCatatanProyek">Simpan</button>
                </div>
            </div>
            <!-- Section Catatan Proyek -->
        </div>
        <!-- Body Card -->
    </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import {
  getStatusClassName,
  formatCurrency,
  wordingMenuHelper,
  isEmptyAfterTrim
} from '@/helpers'
import {
  ArrowRightIcon,
  CheckIcon,
  LoncengIcon,
  TagRedirect
} from '@/components/icons'
import saveImage from '@/assets/img/survey-selesai.png'

export default {
  name: 'NewCardProject',
  components: {
    ArrowRightIcon,
    CheckIcon,
    LoncengIcon,
    TagRedirect
  },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user'
    }),
    maxHeight () {
      return window.innerWidth <= 768 ? `${this.mobileMaxRows * 2.2}em` : `${this.desktopMaxRows * 2}em`
    },
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    },
    isBS () {
      const roleName = this.user.roles[0].name
      if (roleName === 'Account Manager' || roleName === 'Business Support') {
        return true
      }
      return false
    },
    isSM () {
      const roleName = this.user.roles[0].name
      if (roleName === 'Site Manager') {
        return true
      }
      return false
    },
    detailLink () {
      const currentRole = this.user.roles[0].name

      const isProject = this.data.project_id
      let id
      if (!isProject) {
        if (currentRole === 'Purchasing') {
          id = this.data.offering_id
        } else {
          id = this.data.survey_id
        }
      } else if (isProject) {
        id = this.data.project_id
      }

      if (!isProject) {
        switch (currentRole) {
          case 'Konsultan Survey':
            return `/#/project/survey/${id}/detail`
          case 'Site Manager':
            return `/#/sm/project/survey/${id}/detail`
          case 'Account Manager':
          case 'Business Manager':
            return `/#/am/project/survey/${id}/detail`
          case 'Purchasing':
            return `/#/purchasing/project/offering/${id}/detail`
          default:
            return '#'
        }
      } else {
        switch (currentRole) {
          case 'Konsultan Survey':
            return `/#/project/${id}/detail`
          case 'Site Manager':
            return `/#/sm/project/${id}/detail`
          case 'Account Manager':
          case 'Business Manager':
            return `/#/am/project/${id}/detail`
          case 'Purchasing':
            return `/#/purchasing/project/${id}/detail`
          default:
            return '#'
        }
      }
    }
  },
  data () {
    return {
      catatanProyek: '',
      defaultRows: 1,
      desktopMaxRows: 3,
      mobileMaxRows: 4,
      isChangeInput: false
    }
  },
  methods: {
    getClassName (status) {
      return getStatusClassName(status)
    },
    convertConfirmation (date) {
      if (!date) {
        return '-'
      }
      return moment(date).format('DD/MM/YYYY')
    },
    convertDate (date) {
      if (!date) {
        return '-'
      }
      return moment(date).format('DD MMMM YYYY')
    },
    lastUpdateNote (name, date) {
      if (!name || !date) {
        return '-'
      } else {
        return `${name} (${moment(date).format('DD/MM/YYYY - HH:mm')})`
      }
    },
    showModalProyekDarurat (projectUrgent, surveyId) {
      const data = {
        projectUrgent: projectUrgent,
        surveyId: surveyId
      }
      this.$emit('showModalProyekDarurat', data)
    },
    lihatCatatanProyek () {
      this.$emit('lihatCatatanProyek', this.data.survey_id)
    },
    showModalInvoiceTagihan (data) {
      if (data.projectId) {
        this.$emit('showModalInvoiceTagihan', data)
      }
    },
    showModalTanggalPenagihan (data) {
      if (data.projectId) {
        this.$emit('showModalTanggalPenagihan', data)
      }
    },
    autoExpand (event) {
      const isEmpty = isEmptyAfterTrim(event.target.value)
      if (isEmpty) {
        this.isChangeInput = false
      } else {
        this.isChangeInput = true
      }

      event.target.style.height = 'auto'
      event.target.style.height = `${event.target.scrollHeight}px`

      // Mengatur overflow
      if (event.target.scrollHeight > event.target.clientHeight + 2) {
        event.target.style.overflowY = 'scroll'
      } else {
        event.target.style.overflowY = 'hidden'
      }
    },
    calculateRows (text) {
      const minRow = 1
      const maxRowDekstop = 4
      const maxRowMobile = 5

      if (text) {
        const newlineCount = (text.match(/\n/g) || []).length + 1

        if (this.isMobile) {
          if (newlineCount > maxRowMobile) {
            return maxRowMobile
          }
        } else {
          if (newlineCount > maxRowDekstop) {
            return maxRowDekstop
          }
        }

        return newlineCount
      }
      return minRow
    },
    saveCatatanProyek () {
      if (this.isChangeInput === false) {
        this.$buefy.toast.open({
          message: '<p class="averta-regular">Isi catatan terlebih dahulu</p>',
          type: 'is-danger'
        })
        return
      }

      this.$swal({
        imageUrl: saveImage,
        imageAlt: 'save data',
        imageHeight: '200px',
        width: '460px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Catatan Baru Berhasil Ditambahkan</p>',
        html: '<p>Periksa ulang catatan yang ditambahkan telah benar dan sesuai</p>',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn-hapus-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            survey_id: this.data.survey_id,
            notes: this.catatanProyek
          }

          this.$store
            .dispatch('project/updateProjectNote', payload)
            .then(() => {
              this.$buefy.toast.open({
                message: '<p class="averta-regular">Berhasil update catatan</p>',
                type: 'is-success'
              })
            //   this.$emit('reloadList')
            })
            .catch((error) => {
              console.log(error)
              this.$buefy.toast.open({
                message: '<p class="averta-regular">Gagal update catatan, silahkan coba lagi</p>',
                type: 'is-danger'
              })
            })
        }
      })
    },
    formatThousand (amount) {
      return formatCurrency(amount)
    },
    countingConfirmation (data) {
      let count = 0
      data.forEach((el) => {
        if (el.created_at !== null) {
          count = count + 1
        }
      })
      return count
    },
    confirmationWorker (data) {
      let count = 0
      data.forEach((el) => {
        if (el.checked === true) {
          count = count + 1
        }
      })
      return count
    },
    showModalBeforeAfter (data) {
      this.$emit('showModalBeforeAfter', data)
    },
    detailProject (e, data) {
      e.preventDefault()
      this.$emit('detailProject', data)
    },
    dataHighlight (data, type, type2) {
      if (data.hightlight) {
        return data.hightlight.includes(type) || data.hightlight.includes(type2)
      }
      return false
    },
    copyToClipboard (text) {
      navigator.clipboard.writeText(text)
      this.$buefy.toast.open({
        message:
            '<p class="averta-bold">Tersalin!</p>',
        type: 'is-success'
      })
    },
    wordingHelper (value) {
      return wordingMenuHelper(value)
    },
    showTagRescheduleExtend (status) {
      if (status === 1) {
        return true
      }
      return false
    },
    redirectToProjectParent (data) {
      // eslint-disable-next-line camelcase
      const { type, project_parent_id } = data.survey_complain

      if (this.isBS || this.isSM) {
        const basePath = this.isBS ? '/am' : '/sm'
        // eslint-disable-next-line camelcase
        const projectId = type === 'parent' ? data.project_id : project_parent_id
        const path = { path: `${basePath}/project/${projectId}/detail` }
        if (type === 'parent') {
          path.query = { redirect: true }
        }

        const routeData = this.$router.resolve(path)
        window.open(routeData.href, '_blank')
      }
    }
  },
  mounted () {
    this.catatanProyek = this.data.last_project_note.note
  },
  watch: {
    data (newVal) {
      this.catatanProyek = newVal.last_project_note.note
    }
  }
}
</script>

<style lang="scss" scoped>

.hightlight {
    background: #F1D900 !important;
}

.wrap-store {
    max-width: 270px;
}

.wrap-consultan {
    max-width: 230px;
}

.wrap-date-survey {
    max-width: 113px;
}

.wrap-date-project {
    max-width: 135px;
}

.wrap-invoice {
    max-width: 280px;
}

.wrap-grand-total {
    max-width: 160px;
}

.wrap-detail {
    max-width: 280px;
}

.invoice-number {
    max-width: 72px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
}

.catatan-proyek {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow-y: auto;
  transition: height 0.2s;
}

.catatan-proyek:focus {
  outline: none;
}

.address {
    white-space: nowrap;
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pic-wrap {
    white-space: nowrap;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
}

.border-right {
    border-right: solid 1px #C1C7D3;
    padding-right: 20px;
}

.border-right .id-survey {
    width: 80px;
}

.wrap-card {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 20px;
    background: #1974631A;
}

.header-card {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
    background: linear-gradient(90deg, #FFDDDD 0%, #FFEFDB 99.97%);
    border-radius: 20px 20px 0px 0px;
    // overflow-x: auto;
    padding: 28px 28px 34px 28px;
}

.wrap-logo {
    height: 24px;
    object-fit: contain;
}

.wrap-logo-status-mobile {
    display: none !important;
}

.body-card {
    background: white;
    border-radius: 0px 0px 20px 20px;
}

.wrap-tooltip {
    width: 300px;
    white-space: wrap;
    text-align: left;
}

.wrap-tooltip.pic-tooltip {
    width: 200px;
}

.button-detail {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    border: solid 1px #D9272D;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-detail.isMobile {
    width: 100%;
    color: #D9272D;
}

.wrapper-confirmation {
    border-top: 2px dashed #868686;
    background: #fafafa;
}

.wrapper-confirmation-item {
    background: #ffffff;
    padding: 12px 24px;
}

.wrapper-confirmation-item:not(:last-child) {
    border-right: 1px solid #C1C7D3;
}

.unchecked {
    width: 24px;
    height: 24px;
    border: 1px solid #868686;
    border-radius: 100px;
}

.btn-lihat-detail {
    border: 1px solid #d9272d;
    border-radius: 20px;
    padding: 12px 24px;
    font-family: 'Averta-Bold';
    background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    cursor: pointer;
}

.wrapper-material {
    border-top: 2px dashed #868686;
    background: #fafafa;
}

.card-estimasi {
  background: #535353;
  border-radius: 12px;
  color: white;
}

.card-material {
  background: white;
  margin: 0px 8px;
  border-radius: 0px 12px 12px 0px;
}

.border-left-red {
  border-left: 4px solid #D92727;
}

.border-left-yellow {
  border-left: 4px solid #E8C616;
}

.border-left-green {
  border-left: 4px solid #2EDB12;
}

.wrap-catatan-proyek {
    background: #FFFAFA;
}

.btn-history-catatan {
    border-radius: 100px;
    border: solid 1px #9A9A9A;
    color: #9A9A9A;
    background: transparent;
    padding: 8px 20px;
}

.btn-history-catatan.active {
    border: solid 1px #D9272D;
    color: #D9272D;
}

.btn-simpan {
    background: #898989;
    border-radius: 100px;
    color: white;
    padding: 10px 22px;
    border: none;
}

.btn-simpan.active {
    background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
}

.wrap-catatan-proyek-title {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}

.image-upload {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 6px;
}

.highlight-invoice {
    padding: 12px 24px;
    overflow-x: auto;
    width: auto;
    max-width: 1280px;
}

.img-copy {
    max-width: none;
}

.wrapper-list-worker {
    overflow: auto;
    max-height: 20em;
}

.wrap-tag-reschedule-extends {
  background: #E1EEFF;
  border: 1px solid #3185FC;
  padding: 3px 10px;
  border-radius: 100px;
  margin-left: 8px;
}

.wrap-tag-reschedule-extends span {
  color: #3185FC;
  font-size: 14px;
  font-style: italic;
}

@media only screen and (max-width: 600px) {
    .header-card {
        display: block !important;
        padding: 15px;
    }

    .section-detail-project {
        display: block !important;
    }

    .wrap-store, .wrap-consultan, .wrap-date-survey, .wrap-date-project, .wrap-invoice, .wrap-grand-total, .wrap-detail {
        margin-bottom: 24px;
    }

    .wrap-logo-status-mobile {
        display: block !important;
    }

    .wrap-logo-status {
        display: none !important;
    }

    .wrap-logo {
        background: none;
        padding: 0px;
        border-radius: 0px;
    }

    .wrap-catatan-proyek-title {
        display: block !important;
    }

    .wrap-detail {
        width: 100%;
        max-width: 100%;
    }

    .status-survey {
        height: auto !important;
        min-width: none;
    }
}
</style>
